export { faAddressBook as fadAddressBook } from '@fortawesome/pro-duotone-svg-icons';
export { faAddressCard as fadAddressCard } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowDown as fadArrowDown } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowDownLeft as fadArrowDownLeft } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowDownLong as fadArrowDownLong } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowDownToLine as fadArrowDownToLine } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowLeftLong as fadArrowLeftLong } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowLeftToLine as fadArrowLeftToLine } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowRightFromBracket as fadArrowRightFromBracket } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowRightLong as fadArrowRightLong } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowRightArrowLeft as fadArrowRightArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowRightToBracket as fadArrowRightToBracket } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowRightToLine as fadArrowRightToLine } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowTurnDownLeft as fadArrowTurnDownLeft } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowUp as fadArrowUp } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowUpArrowDown as fadArrowUpArrowDown } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowUpToLine as fadArrowUpToLine } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowUpLong as fadArrowUpLong } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowUpRight as fadArrowUpRight } from '@fortawesome/pro-duotone-svg-icons';
export { faArrowUpRightFromSquare as fadArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons';
export { faAt as fadAt } from '@fortawesome/pro-duotone-svg-icons';
export { faBadgeCheck as fadBadgeCheck } from '@fortawesome/pro-duotone-svg-icons';
export { faBadgePercent as fadBadgePercent } from '@fortawesome/pro-duotone-svg-icons';
export { faBars as fadBars } from '@fortawesome/pro-duotone-svg-icons';
export { faBarsFilter as fadBarsFilter } from '@fortawesome/pro-duotone-svg-icons';
export { faBarsSort as fadBarsSort } from '@fortawesome/pro-duotone-svg-icons';
export { faBasketShopping as fadBasketShopping } from '@fortawesome/pro-duotone-svg-icons';
export { faBell as fadBell } from '@fortawesome/pro-duotone-svg-icons';
export { faBells as fadBells } from '@fortawesome/pro-duotone-svg-icons';
export { faBolt as fadBolt } from '@fortawesome/pro-duotone-svg-icons';
export { faBook as fadBook } from '@fortawesome/pro-duotone-svg-icons';
export { faBooks as fadBooks } from '@fortawesome/pro-duotone-svg-icons';
export { faBookmark as fadBookmark } from '@fortawesome/pro-duotone-svg-icons';
export { faBox as fadBox } from '@fortawesome/pro-duotone-svg-icons';
export { faBoxArchive as fadBoxArchive } from '@fortawesome/pro-duotone-svg-icons';
export { faBoxOpen as fadBoxOpen } from '@fortawesome/pro-duotone-svg-icons';
export { faBoxTaped as fadBoxTaped } from '@fortawesome/pro-duotone-svg-icons';
export { faBracketsCurly as fadBracketsCurly } from '@fortawesome/pro-duotone-svg-icons';
export { faBriefcase as fadBriefcase } from '@fortawesome/pro-duotone-svg-icons';
export { faBroom as fadBroom } from '@fortawesome/pro-duotone-svg-icons';
export { faBroomWide as fadBroomWide } from '@fortawesome/pro-duotone-svg-icons';
export { faBrowser as fadBrowser } from '@fortawesome/pro-duotone-svg-icons';
export { faBrush as fadBrush } from '@fortawesome/pro-duotone-svg-icons';
export { faBug as fadBug } from '@fortawesome/pro-duotone-svg-icons';
export { faBuilding as fadBuilding } from '@fortawesome/pro-duotone-svg-icons';
export { faBuildingColumns as fadBuildingColumns } from '@fortawesome/pro-duotone-svg-icons';
export { faBullhorn as fadBullhorn } from '@fortawesome/pro-duotone-svg-icons';
export { faCalendar as fadCalendar } from '@fortawesome/pro-duotone-svg-icons';
export { faCaretDown as fadCaretDown } from '@fortawesome/pro-duotone-svg-icons';
export { faCaretUp as fadCaretUp } from '@fortawesome/pro-duotone-svg-icons';
export { faCartShopping as fadCartShopping } from '@fortawesome/pro-duotone-svg-icons';
export { faChartLineUp as fadChartLineUp } from '@fortawesome/pro-duotone-svg-icons';
export { faChartSimple as fadChartSimple } from '@fortawesome/pro-duotone-svg-icons';
export { faChartPieAlt as fadChartPieAlt } from '@fortawesome/pro-duotone-svg-icons';
export { faCheck as fadCheck } from '@fortawesome/pro-duotone-svg-icons';
export { faChevronDoubleLeft as fadChevronDoubleLeft } from '@fortawesome/pro-duotone-svg-icons';
export { faChevronDoubleRight as fadChevronDoubleRight } from '@fortawesome/pro-duotone-svg-icons';
export { faChevronDown as fadChevronDown } from '@fortawesome/pro-duotone-svg-icons';
export { faChevronLeft as fadChevronLeft } from '@fortawesome/pro-duotone-svg-icons';
export { faChevronRight as fadChevronRight } from '@fortawesome/pro-duotone-svg-icons';
export { faChevronUp as fadChevronUp } from '@fortawesome/pro-duotone-svg-icons';
export { faCircle as fadCircle } from '@fortawesome/pro-duotone-svg-icons';
export { faCircleArrowDown as fadCircleArrowDown } from '@fortawesome/pro-duotone-svg-icons';
export { faCircleArrowUp as fadCircleArrowUp } from '@fortawesome/pro-duotone-svg-icons';
export { faCircleCheck as fadCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
export { faCircleDollar as fadCircleDollar } from '@fortawesome/pro-duotone-svg-icons';
export { faCircleExclamation as fadCircleExclamation } from '@fortawesome/pro-duotone-svg-icons';
export { faCircleHeart as fadCircleHeart } from '@fortawesome/pro-duotone-svg-icons';
export { faCircleInfo as fadCircleInfo } from '@fortawesome/pro-duotone-svg-icons';
export { faCirclePlus as fadCirclePlus } from '@fortawesome/pro-duotone-svg-icons';
export { faCircleStar as fadCircleStar } from '@fortawesome/pro-duotone-svg-icons';
export { faCircleQuestion as fadCircleQuestion } from '@fortawesome/pro-duotone-svg-icons';
export { faCircleXmark as fadCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
export { faCity as fadCity } from '@fortawesome/pro-duotone-svg-icons';
export { faClipboardList as fadClipboardList } from '@fortawesome/pro-duotone-svg-icons';
export { faClock as fadClock } from '@fortawesome/pro-duotone-svg-icons';
export { faClockRotateLeft as fadClockRotateLeft } from '@fortawesome/pro-duotone-svg-icons';
export { faCloud as fadCloud } from '@fortawesome/pro-duotone-svg-icons';
export { faCode as fadCode } from '@fortawesome/pro-duotone-svg-icons';
export { faCodeBranch as fadCodeBranch } from '@fortawesome/pro-duotone-svg-icons';
export { faCodeCommit as fadCodeCommit } from '@fortawesome/pro-duotone-svg-icons';
export { faCodeFork as fadCodeFork } from '@fortawesome/pro-duotone-svg-icons';
export { faComment as fadComment } from '@fortawesome/pro-duotone-svg-icons';
export { faComments as fadComments } from '@fortawesome/pro-duotone-svg-icons';
export { faComputer as fadComputer } from '@fortawesome/pro-duotone-svg-icons';
export { faCoins as fadCoins } from '@fortawesome/pro-duotone-svg-icons';
export { faCommentLines as fadCommentLines } from '@fortawesome/pro-duotone-svg-icons';
export { faCompress as fadCompress } from '@fortawesome/pro-duotone-svg-icons';
export { faConveyorBelt as fadConveyorBelt } from '@fortawesome/pro-duotone-svg-icons';
export { faCopy as fadCopy } from '@fortawesome/pro-duotone-svg-icons';
export { faCreditCard as fadCreditCard } from '@fortawesome/pro-duotone-svg-icons';
export { faCrown as fadCrown } from '@fortawesome/pro-duotone-svg-icons';
export { faCube as fadCube } from '@fortawesome/pro-duotone-svg-icons';
export { faCubes as fadCubes } from '@fortawesome/pro-duotone-svg-icons';
export { faDatabase as fadDatabase } from '@fortawesome/pro-duotone-svg-icons';
export { faDollarSign as fadDollarSign } from '@fortawesome/pro-duotone-svg-icons';
export { faDoorOpen as fadDoorOpen } from '@fortawesome/pro-duotone-svg-icons';
export { faDoorClosed as fadDoorClosed } from '@fortawesome/pro-duotone-svg-icons';
export { faDesktop as fadDesktop } from '@fortawesome/pro-duotone-svg-icons';
export { faEarth as fadEarth } from '@fortawesome/pro-duotone-svg-icons';
export { faEllipsis as fadEllipsis } from '@fortawesome/pro-duotone-svg-icons';
export { faEllipsisVertical as fadEllipsisVertical } from '@fortawesome/pro-duotone-svg-icons';
export { faEnvelope as fadEnvelope } from '@fortawesome/pro-duotone-svg-icons';
export { faEnvelopeOpenText as fadEnvelopeOpenText } from '@fortawesome/pro-duotone-svg-icons';
export { faExpand as fadExpand } from '@fortawesome/pro-duotone-svg-icons';
export { faExpandAlt as fadExpandAlt } from '@fortawesome/pro-duotone-svg-icons';
export { faEye as fadEye } from '@fortawesome/pro-duotone-svg-icons';
export { faEyes as fadEyes } from '@fortawesome/pro-duotone-svg-icons';
export { faEyeSlash as fadEyeSlash } from '@fortawesome/pro-duotone-svg-icons';
export { faFaceParty as fadFaceParty } from '@fortawesome/pro-duotone-svg-icons';
export { faFaceSmile as fadFaceSmile } from '@fortawesome/pro-duotone-svg-icons';
export { faFile as fadFile } from '@fortawesome/pro-duotone-svg-icons';
export { faFileContract as fadFileContract } from '@fortawesome/pro-duotone-svg-icons';
export { faFileInvoice as fadFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
export { faFileZipper as fadFileZipper } from '@fortawesome/pro-duotone-svg-icons';
export { faFilter as fadFilter } from '@fortawesome/pro-duotone-svg-icons';
export { faFire as fadFire } from '@fortawesome/pro-duotone-svg-icons';
export { faFlag as fadFlag } from '@fortawesome/pro-duotone-svg-icons';
export { faFlaskRoundPotion as fadFlaskRoundPotion } from '@fortawesome/pro-duotone-svg-icons';
export { faFolder as fadFolder } from '@fortawesome/pro-duotone-svg-icons';
export { faFolderOpen as fadFolderOpen } from '@fortawesome/pro-duotone-svg-icons';
export { faFolders as fadFolders } from '@fortawesome/pro-duotone-svg-icons';
export { faGauge as fadGauge } from '@fortawesome/pro-duotone-svg-icons';
export { faGaugeSimple as fadGaugeSimple } from '@fortawesome/pro-duotone-svg-icons';
export { faGaugeSimpleMin as fadGaugeSimpleMin } from '@fortawesome/pro-duotone-svg-icons';
export { faGaugeSimpleMax as fadGaugeSimpleMax } from '@fortawesome/pro-duotone-svg-icons';
export { faGear as fadGear } from '@fortawesome/pro-duotone-svg-icons';
export { faGem as fadGem } from '@fortawesome/pro-duotone-svg-icons';
export { faGift as fadGift } from '@fortawesome/pro-duotone-svg-icons';
export { faGhost as fadGhost } from '@fortawesome/pro-duotone-svg-icons';
export { faGlasses as fadGlasses } from '@fortawesome/pro-duotone-svg-icons';
export { faGlobe as fadGlobe } from '@fortawesome/pro-duotone-svg-icons';
export { faGraduationCap as fadGraduationCap } from '@fortawesome/pro-duotone-svg-icons';
export { faGrid2 as fadGrid2 } from '@fortawesome/pro-duotone-svg-icons';
export { faGripDots as fadGripDots } from '@fortawesome/pro-duotone-svg-icons';
export { faGripDotsVertical as fadGripDotsVertical } from '@fortawesome/pro-duotone-svg-icons';
export { faHandshake as fadHandshake } from '@fortawesome/pro-duotone-svg-icons';
export { faHandWave as fadHandWave } from '@fortawesome/pro-duotone-svg-icons';
export { faHandPointUp as fadHandPointUp } from '@fortawesome/pro-duotone-svg-icons';
export { faHandsHoldingCircle as fadHandsHoldingCircle } from '@fortawesome/pro-duotone-svg-icons';
export { faHatWizard as fadHatWizard } from '@fortawesome/pro-duotone-svg-icons';
export { faHashtag as fadHashtag } from '@fortawesome/pro-duotone-svg-icons';
export { faHeadset as fadHeadset } from '@fortawesome/pro-duotone-svg-icons';
export { faHeart as fadHeart } from '@fortawesome/pro-duotone-svg-icons';
export { faHome as fadHome } from '@fortawesome/pro-duotone-svg-icons';
export { faHundredPoints as fadHundredPoints } from '@fortawesome/pro-duotone-svg-icons';
export { faIdBadge as fadIdBadge } from '@fortawesome/pro-duotone-svg-icons';
export { faImage as fadImage } from '@fortawesome/pro-duotone-svg-icons';
export { faImages as fadImages } from '@fortawesome/pro-duotone-svg-icons';
export { faInfo as fadInfo } from '@fortawesome/pro-duotone-svg-icons';
export { faKey as fadKey } from '@fortawesome/pro-duotone-svg-icons';
export { faLanguage as fadLanguage } from '@fortawesome/pro-duotone-svg-icons';
export { faLayerGroup as fadLayerGroup } from '@fortawesome/pro-duotone-svg-icons';
export { faLifeRing as fadLifeRing } from '@fortawesome/pro-duotone-svg-icons';
export { faLightbulb as fadLightbulb } from '@fortawesome/pro-duotone-svg-icons';
export { faLink as fadLink } from '@fortawesome/pro-duotone-svg-icons';
export { faList as fadList } from '@fortawesome/pro-duotone-svg-icons';
export { faListCheck as fadListCheck } from '@fortawesome/pro-duotone-svg-icons';
export { faListTimeline as fadListTimeline } from '@fortawesome/pro-duotone-svg-icons';
export { faListOl as fadListOl } from '@fortawesome/pro-duotone-svg-icons';
export { faLocationArrow as fadLocationArrow } from '@fortawesome/pro-duotone-svg-icons';
export { faLocationPin as fadLocationPin } from '@fortawesome/pro-duotone-svg-icons';
export { faLock as fadLock } from '@fortawesome/pro-duotone-svg-icons';
export { faMagnifyingGlass as fadMagnifyingGlass } from '@fortawesome/pro-duotone-svg-icons';
export { faMap as fadMap } from '@fortawesome/pro-duotone-svg-icons';
export { faMapSigns as fadMapSigns } from '@fortawesome/pro-duotone-svg-icons';
export { faMedal as fadMedal } from '@fortawesome/pro-duotone-svg-icons';
export { faMegaphone as fadMegaphone } from '@fortawesome/pro-duotone-svg-icons';
export { faMessage as fadMessage } from '@fortawesome/pro-duotone-svg-icons';
export { faMicrophone as fadMicrophone } from '@fortawesome/pro-duotone-svg-icons';
export { faMicrophoneSlash as fadMicrophoneSlash } from '@fortawesome/pro-duotone-svg-icons';
export { faMinus as farMinus } from '@fortawesome/pro-duotone-svg-icons';
export { faMobile as fadMobile } from '@fortawesome/pro-duotone-svg-icons';
export { faMoneyBill as fadMoneyBill } from '@fortawesome/pro-duotone-svg-icons';
export { faMoneyBillWave as fadMoneyBillWave } from '@fortawesome/pro-duotone-svg-icons';
export { faMoneyBillTransfer as fadMoneyBillTransfer } from '@fortawesome/pro-duotone-svg-icons';
export { faMoneyCheck as fadMoneyCheck } from '@fortawesome/pro-duotone-svg-icons';
export { faMoon as fadMoon } from '@fortawesome/pro-duotone-svg-icons';
export { faNewspaper as fadNewspaper } from '@fortawesome/pro-duotone-svg-icons';
export { faObjectGroup as fadObjectGroup } from '@fortawesome/pro-duotone-svg-icons';
export { faPaperPlane as fadPaperPlane } from '@fortawesome/pro-duotone-svg-icons';
export { faPercent as fadPercent } from '@fortawesome/pro-duotone-svg-icons';
export { faPencil as fadPencil } from '@fortawesome/pro-duotone-svg-icons';
export { faPenFancy as fadPenFancy } from '@fortawesome/pro-duotone-svg-icons';
export { faPeopleGroup as fadPeopleGroup } from '@fortawesome/pro-duotone-svg-icons';
export { faPersonWalking as fadPersonWalking } from '@fortawesome/pro-duotone-svg-icons';
export { faPersonWalkingLuggage as fadPersonWalkingLuggage } from '@fortawesome/pro-duotone-svg-icons';
export { faPhone as fadPhone } from '@fortawesome/pro-duotone-svg-icons';
export { faPhoneSlash as fadPhoneSlash } from '@fortawesome/pro-duotone-svg-icons';
export { faPiggyBank as fadPiggyBank } from '@fortawesome/pro-duotone-svg-icons';
export { faPlay as fadPlay } from '@fortawesome/pro-duotone-svg-icons';
export { faPlus as fadPlus } from '@fortawesome/pro-duotone-svg-icons';
export { faPowerOff as fadPowerOff } from '@fortawesome/pro-duotone-svg-icons';
export { faQuestion as fadQuestion } from '@fortawesome/pro-duotone-svg-icons';
export { faRightLeft as fadRightLeft } from '@fortawesome/pro-duotone-svg-icons';
export { faRightFromBracket as fadRightFromBracket } from '@fortawesome/pro-duotone-svg-icons';
export { faRightToBracket as fadRightToBracket } from '@fortawesome/pro-duotone-svg-icons';
export { faRocket as fadRocket } from '@fortawesome/pro-duotone-svg-icons';
export { faRotate as fadRotate } from '@fortawesome/pro-duotone-svg-icons';
export { faRectangleHistory as fadRectangleHistory } from '@fortawesome/pro-duotone-svg-icons';
export { faRss as fadRss } from '@fortawesome/pro-duotone-svg-icons';
export { faSackDollar as fadSackDollar } from '@fortawesome/pro-duotone-svg-icons';
export { faScaleBalanced as fadScaleBalanced } from '@fortawesome/pro-duotone-svg-icons';
export { faServer as fadServer } from '@fortawesome/pro-duotone-svg-icons';
export { faShare as fadShare } from '@fortawesome/pro-duotone-svg-icons';
export { faShield as fadShield } from '@fortawesome/pro-duotone-svg-icons';
export { faShop as fadShop } from '@fortawesome/pro-duotone-svg-icons';
export { faSignIn as fadSignIn } from '@fortawesome/pro-duotone-svg-icons';
export { faSlashForward as fadSlashForward } from '@fortawesome/pro-duotone-svg-icons';
export { faSliders as fadSliders } from '@fortawesome/pro-duotone-svg-icons';
export { faSort as fadSort } from '@fortawesome/pro-duotone-svg-icons';
export { faSortDown as fadSortDown } from '@fortawesome/pro-duotone-svg-icons';
export { faSortUp as fadSortUp } from '@fortawesome/pro-duotone-svg-icons';
export { faSparkle as fadSparkle } from '@fortawesome/pro-duotone-svg-icons';
export { faSparkles as fadSparkles } from '@fortawesome/pro-duotone-svg-icons';
export { faStar as fadStar } from '@fortawesome/pro-duotone-svg-icons';
export { faStop as fadStop } from '@fortawesome/pro-duotone-svg-icons';
export { faSun as fadSun } from '@fortawesome/pro-duotone-svg-icons';
export { faSquare as fadSquare } from '@fortawesome/pro-duotone-svg-icons';
export { faSquareCode as fadSquareCode } from '@fortawesome/pro-duotone-svg-icons';
export { faSync as fadSync } from '@fortawesome/pro-duotone-svg-icons';
export { faTag as fadTag } from '@fortawesome/pro-duotone-svg-icons';
export { faTeddyBear as fadTeddyBear } from '@fortawesome/pro-duotone-svg-icons';
export { faTerminal as fadTerminal } from '@fortawesome/pro-duotone-svg-icons';
export { faThumbsUp as fadThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
export { faThoughtBubble as fadThoughtBubble } from '@fortawesome/pro-duotone-svg-icons';
export { faTimelineArrow as fadTimelineArrow } from '@fortawesome/pro-duotone-svg-icons';
export { faTimer as fadTimer } from '@fortawesome/pro-duotone-svg-icons';
export { faToggleOff as fadToggleOff } from '@fortawesome/pro-duotone-svg-icons';
export { faToggleOn as fadToggleOn } from '@fortawesome/pro-duotone-svg-icons';
export { faTrafficLight as fadTrafficLight } from '@fortawesome/pro-duotone-svg-icons';
export { faTrash as fadTrash } from '@fortawesome/pro-duotone-svg-icons';
export { faTriangleExclamation as fadTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';
export { faTypewriter as fadTypewriter } from '@fortawesome/pro-duotone-svg-icons';
export { faUniversity as fadUniversity } from '@fortawesome/pro-duotone-svg-icons';
export { faUser as fadUser } from '@fortawesome/pro-duotone-svg-icons';
export { faUserAstronaut as fadUserAstronaut } from '@fortawesome/pro-duotone-svg-icons';
export { faUserCircle as fadUserCircle } from '@fortawesome/pro-duotone-svg-icons';
export { faUserCrown as fadUserCrown } from '@fortawesome/pro-duotone-svg-icons';
export { faUserGroup as fadUserGroup } from '@fortawesome/pro-duotone-svg-icons';
export { faUserPlus as fadUserPlus } from '@fortawesome/pro-duotone-svg-icons';
export { faUsers as fadUsers } from '@fortawesome/pro-duotone-svg-icons';
export { faVideo as fadVideo } from '@fortawesome/pro-duotone-svg-icons';
export { faVideoSlash as fadVideoSlash } from '@fortawesome/pro-duotone-svg-icons';
export { faWandMagicSparkles as fadWandMagicSparkles } from '@fortawesome/pro-duotone-svg-icons';
export { faWavePulse as fadWavePulse } from '@fortawesome/pro-duotone-svg-icons';
export { faWebhook as fadWebhook } from '@fortawesome/pro-duotone-svg-icons';
export { faWrench as fadWrench } from '@fortawesome/pro-duotone-svg-icons';
export { faXmark as fadXmark } from '@fortawesome/pro-duotone-svg-icons';
