import React, { useEffect, useState } from 'react';

import { PageLoader } from '@playbooks/interface/loaders';
import { useQuery } from 'hooks';
import { timeout } from 'utils';
import * as logger from 'utils/logger';

type SubmitCollectionContext = {
	collection: any;
	setCollection: any;
	rootApi: string;
	view: string;
	onView: any;
};

export const SubmitCollectionContext = React.createContext<SubmitCollectionContext>(null);

const SubmitCollectionProvider = ({ account, router, session, store, toast, children }) => {
	const [collection, setCollection] = useState<any>({});
	const { collectionId, view = 'basics' } = router.query;

	// Computed
	const rootApi = `${account.rootApi}/collections/${collectionId}`;

	// Hooks
	useEffect(() => {
		if (session.loaded) session.isAuthenticated() ? fetchData() : '/login';
		return () => onClear();
	}, [session.loaded]);

	useEffect(() => {
		logger.debug('collectionContext: ', collection);
	}, [collection]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		await timeout(1000);
		const response = await store.queryRecord({
			url: rootApi,
			params: { include: ['language', 'framework', 'tool', 'topic', 'repos'] },
		});
		setCollection(response.data);
	});

	useEffect(() => {
		if (error) router.push('/403');
	}, [error]);

	// Methods
	const onView = view => {
		router.push(`/submit/collection/${collection.uuid}?view=${view}`);
		window.scrollTo(0, 0);
	};

	const onClear = () => {
		setCollection({});
	};

	// Render
	return (
		<SubmitCollectionContext.Provider value={{ collection, setCollection, rootApi, view, onView }}>
			{collection.id ? children : <PageLoader title='Hang tight' message='Loading your workflow...' />}
		</SubmitCollectionContext.Provider>
	);
};

const useSubmitCollection = () => {
	return React.useContext(SubmitCollectionContext);
};

export { SubmitCollectionProvider, useSubmitCollection };
