import React, { useEffect, useState } from 'react';

import { PageLoader } from '@playbooks/interface/loaders';
import { useQuery } from 'hooks';
import { timeout } from 'utils';
import * as logger from 'utils/logger';

type SubmitRepoContext = {
	repo: any;
	setRepo: any;
	rootApi: string;
	view: string;
	onView: any;
};

export const SubmitRepoContext = React.createContext<SubmitRepoContext>(null);

const SubmitRepoProvider = ({ account, router, session, store, toast, children }) => {
	const [repo, setRepo] = useState<any>({});
	const { repoId, view = 'welcome' } = router.query;

	// Computed
	const rootApi = `${account.rootApi}/repos/${repoId}`;

	// Hooks
	useEffect(() => {
		if (session.loaded) session.isAuthenticated() ? fetchData() : '/login';
		return () => onClear();
	}, [session.loaded]);

	useEffect(() => {
		logger.debug('repoContext: ', repo);
	}, [repo]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		await timeout(1000);
		const response = await store.queryRecord({
			url: rootApi,
			params: {
				include: [
					'demo',
					'frameworks',
					'language',
					'license',
					'medias',
					'platform',
					'quality',
					'setup',
					'tools',
					'topics',
				],
			},
		});
		setRepo(response.data);
	});

	useEffect(() => {
		if (error) router.push('/403');
	}, [error]);

	// Methods
	const onView = view => {
		router.push(`/submit/repo/${repo.uuid}?view=${view}`);
		window.scrollTo(0, 0);
	};

	const onClear = () => {
		setRepo({});
	};

	// Render
	return (
		<SubmitRepoContext.Provider value={{ repo, setRepo, rootApi, view, onView }}>
			{repo.id ? children : <PageLoader title='Hang Tight' message='Loading your workflow...' />}
		</SubmitRepoContext.Provider>
	);
};

const useSubmitRepo = () => {
	return React.useContext(SubmitRepoContext);
};

export { SubmitRepoProvider, useSubmitRepo };
