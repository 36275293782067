export { faAddressBook as farAddressBook } from '@fortawesome/pro-regular-svg-icons';
export { faAddressCard as farAddressCard } from '@fortawesome/pro-regular-svg-icons';
export { faArrowDown as farArrowDown } from '@fortawesome/pro-regular-svg-icons';
export { faArrowDownLeft as farArrowDownLeft } from '@fortawesome/pro-regular-svg-icons';
export { faArrowDownLong as farArrowDownLong } from '@fortawesome/pro-regular-svg-icons';
export { faArrowDownToLine as farArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
export { faArrowLeftLong as farArrowLeftLong } from '@fortawesome/pro-regular-svg-icons';
export { faArrowLeftToLine as farArrowLeftToLine } from '@fortawesome/pro-regular-svg-icons';
export { faArrowRightFromBracket as farArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
export { faArrowRightLong as farArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
export { faArrowRightArrowLeft as farArrowRightArrowLeft } from '@fortawesome/pro-regular-svg-icons';
export { faArrowRightToBracket as farArrowRightToBracket } from '@fortawesome/pro-regular-svg-icons';
export { faArrowRightToLine as farArrowRightToLine } from '@fortawesome/pro-regular-svg-icons';
export { faArrowTurnDownLeft as farArrowTurnDownLeft } from '@fortawesome/pro-regular-svg-icons';
export { faArrowUp as farArrowUp } from '@fortawesome/pro-regular-svg-icons';
export { faArrowUpArrowDown as farArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
export { faArrowUpToLine as farArrowUpToLine } from '@fortawesome/pro-regular-svg-icons';
export { faArrowUpLong as farArrowUpLong } from '@fortawesome/pro-regular-svg-icons';
export { faArrowUpRight as farArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
export { faArrowUpRightFromSquare as farArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
export { faAt as farAt } from '@fortawesome/pro-regular-svg-icons';
export { faBadgeCheck as farBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
export { faBadgePercent as farBadgePercent } from '@fortawesome/pro-regular-svg-icons';
export { faBars as farBars } from '@fortawesome/pro-regular-svg-icons';
export { faBarsFilter as farBarsFilter } from '@fortawesome/pro-regular-svg-icons';
export { faBarsSort as farBarsSort } from '@fortawesome/pro-regular-svg-icons';
export { faBasketShopping as farBasketShopping } from '@fortawesome/pro-regular-svg-icons';
export { faBell as farBell } from '@fortawesome/pro-regular-svg-icons';
export { faBells as farBells } from '@fortawesome/pro-regular-svg-icons';
export { faBlockQuote as farBlockQuote } from '@fortawesome/pro-regular-svg-icons';
export { faBold as farBold } from '@fortawesome/pro-regular-svg-icons';
export { faBolt as farBolt } from '@fortawesome/pro-regular-svg-icons';
export { faBook as farBook } from '@fortawesome/pro-regular-svg-icons';
export { faBooks as farBooks } from '@fortawesome/pro-regular-svg-icons';
export { faBookmark as farBookmark } from '@fortawesome/pro-regular-svg-icons';
export { faBox as farBox } from '@fortawesome/pro-regular-svg-icons';
export { faBoxArchive as farBoxArchive } from '@fortawesome/pro-regular-svg-icons';
export { faBoxOpen as farBoxOpen } from '@fortawesome/pro-regular-svg-icons';
export { faBoxTaped as farBoxTaped } from '@fortawesome/pro-regular-svg-icons';
export { faBracketsCurly as farBracketsCurly } from '@fortawesome/pro-regular-svg-icons';
export { faBriefcase as farBriefcase } from '@fortawesome/pro-regular-svg-icons';
export { faBroom as farBroom } from '@fortawesome/pro-regular-svg-icons';
export { faBroomWide as farBroomWide } from '@fortawesome/pro-regular-svg-icons';
export { faBrowser as farBrowser } from '@fortawesome/pro-regular-svg-icons';
export { faBrush as farBrush } from '@fortawesome/pro-regular-svg-icons';
export { faBug as farBug } from '@fortawesome/pro-regular-svg-icons';
export { faBuilding as farBuilding } from '@fortawesome/pro-regular-svg-icons';
export { faBuildingColumns as farBuildingColumns } from '@fortawesome/pro-regular-svg-icons';
export { faBullhorn as farBullhorn } from '@fortawesome/pro-regular-svg-icons';
export { faCalendar as farCalendar } from '@fortawesome/pro-regular-svg-icons';
export { faCaretDown as farCaretDown } from '@fortawesome/pro-regular-svg-icons';
export { faCaretUp as farCaretUp } from '@fortawesome/pro-regular-svg-icons';
export { faCartShopping as farCartShopping } from '@fortawesome/pro-regular-svg-icons';
export { faChartLineUp as farChartLineUp } from '@fortawesome/pro-regular-svg-icons';
export { faChartSimple as farChartSimple } from '@fortawesome/pro-regular-svg-icons';
export { faChartPieAlt as farChartPieAlt } from '@fortawesome/pro-regular-svg-icons';
export { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons';
export { faChevronDoubleLeft as farChevronDoubleLeft } from '@fortawesome/pro-regular-svg-icons';
export { faChevronDoubleRight as farChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons';
export { faChevronDown as farChevronDown } from '@fortawesome/pro-regular-svg-icons';
export { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons';
export { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons';
export { faChevronUp as farChevronUp } from '@fortawesome/pro-regular-svg-icons';
export { faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons';
export { faCircleArrowDown as farCircleArrowDown } from '@fortawesome/pro-regular-svg-icons';
export { faCircleArrowUp as farCircleArrowUp } from '@fortawesome/pro-regular-svg-icons';
export { faCircleCheck as farCircleCheck } from '@fortawesome/pro-regular-svg-icons';
export { faCircleDollar as farCircleDollar } from '@fortawesome/pro-regular-svg-icons';
export { faCircleExclamation as farCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
export { faCircleHeart as farCircleHeart } from '@fortawesome/pro-regular-svg-icons';
export { faCircleInfo as farCircleInfo } from '@fortawesome/pro-regular-svg-icons';
export { faCirclePlus as farCirclePlus } from '@fortawesome/pro-regular-svg-icons';
export { faCircleQuestion as farCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
export { faCircleStar as farCircleStar } from '@fortawesome/pro-regular-svg-icons';
export { faCircleXmark as farCircleXmark } from '@fortawesome/pro-regular-svg-icons';
export { faCity as farCity } from '@fortawesome/pro-regular-svg-icons';
export { faClipboard as farClipboard } from '@fortawesome/pro-regular-svg-icons';
export { faClipboardList as farClipboardList } from '@fortawesome/pro-regular-svg-icons';
export { faClock as farClock } from '@fortawesome/pro-regular-svg-icons';
export { faCloud as farCloud } from '@fortawesome/pro-regular-svg-icons';
export { faCode as farCode } from '@fortawesome/pro-regular-svg-icons';
export { faCodeSimple as farCodeSimple } from '@fortawesome/pro-regular-svg-icons';
export { faCoins as farCoins } from '@fortawesome/pro-regular-svg-icons';
export { faCodeBranch as farCodeBranch } from '@fortawesome/pro-regular-svg-icons';
export { faCodeCommit as farCodeCommit } from '@fortawesome/pro-regular-svg-icons';
export { faCodeFork as farCodeFork } from '@fortawesome/pro-regular-svg-icons';
export { faComment as farComment } from '@fortawesome/pro-regular-svg-icons';
export { faComments as farComments } from '@fortawesome/pro-regular-svg-icons';
export { faCompress as farCompress } from '@fortawesome/pro-regular-svg-icons';
export { faConveyorBelt as farConveyorBelt } from '@fortawesome/pro-regular-svg-icons';
export { faCopy as farCopy } from '@fortawesome/pro-regular-svg-icons';
export { faCreditCard as farCreditCard } from '@fortawesome/pro-regular-svg-icons';
export { faCrown as farCrown } from '@fortawesome/pro-regular-svg-icons';
export { faCube as farCube } from '@fortawesome/pro-regular-svg-icons';
export { faCubes as farCubes } from '@fortawesome/pro-regular-svg-icons';
export { faDatabase as farDatabase } from '@fortawesome/pro-regular-svg-icons';
export { faDesktop as farDesktop } from '@fortawesome/pro-regular-svg-icons';
export { faDollarSign as farDollarSign } from '@fortawesome/pro-regular-svg-icons';
export { faDoorOpen as farDoorOpen } from '@fortawesome/pro-regular-svg-icons';
export { faDoorClosed as farDoorClosed } from '@fortawesome/pro-regular-svg-icons';
export { faEarth as farEarth } from '@fortawesome/pro-regular-svg-icons';
export { faEllipsis as farEllipsis } from '@fortawesome/pro-regular-svg-icons';
export { faEllipsisVertical as farEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
export { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons';
export { faEnvelopeOpenText as farEnvelopeOpenText } from '@fortawesome/pro-regular-svg-icons';
export { faExpand as farExpand } from '@fortawesome/pro-regular-svg-icons';
export { faExpandAlt as farExpandAlt } from '@fortawesome/pro-regular-svg-icons';
export { faEye as farEye } from '@fortawesome/pro-regular-svg-icons';
export { faEyes as farEyes } from '@fortawesome/pro-regular-svg-icons';
export { faEyeSlash as farEyeSlash } from '@fortawesome/pro-regular-svg-icons';
export { faFaceParty as farFaceParty } from '@fortawesome/pro-regular-svg-icons';
export { faFaceSmile as farFaceSmile } from '@fortawesome/pro-regular-svg-icons';
export { faFile as farFile } from '@fortawesome/pro-regular-svg-icons';
export { faFileContract as farFileContract } from '@fortawesome/pro-regular-svg-icons';
export { faFileInvoice as farFileInvoice } from '@fortawesome/pro-regular-svg-icons';
export { faFileZipper as farFileZipper } from '@fortawesome/pro-regular-svg-icons';
export { faFilter as farFilter } from '@fortawesome/pro-regular-svg-icons';
export { faFire as farFire } from '@fortawesome/pro-regular-svg-icons';
export { faFlag as farFlag } from '@fortawesome/pro-regular-svg-icons';
export { faFlaskRoundPotion as farFlaskRoundPotion } from '@fortawesome/pro-regular-svg-icons';
export { faFolder as farFolder } from '@fortawesome/pro-regular-svg-icons';
export { faFolderOpen as farFolderOpen } from '@fortawesome/pro-regular-svg-icons';
export { faFolders as farFolders } from '@fortawesome/pro-regular-svg-icons';
export { faGauge as farGauge } from '@fortawesome/pro-regular-svg-icons';
export { faGaugeSimple as farGaugeSimple } from '@fortawesome/pro-regular-svg-icons';
export { faGaugeSimpleMin as farGaugeSimpleMin } from '@fortawesome/pro-regular-svg-icons';
export { faGaugeSimpleMax as farGaugeSimpleMax } from '@fortawesome/pro-regular-svg-icons';
export { faGraduationCap as farGraduationCap } from '@fortawesome/pro-regular-svg-icons';
export { faGear as farGear } from '@fortawesome/pro-regular-svg-icons';
export { faGem as farGem } from '@fortawesome/pro-regular-svg-icons';
export { faGhost as farGhost } from '@fortawesome/pro-regular-svg-icons';
export { faGift as farGift } from '@fortawesome/pro-regular-svg-icons';
export { faGlasses as farGlasses } from '@fortawesome/pro-regular-svg-icons';
export { faGlobe as farGlobe } from '@fortawesome/pro-regular-svg-icons';
export { faGrid2 as farGrid2 } from '@fortawesome/pro-regular-svg-icons';
export { faGripDots as farGripDots } from '@fortawesome/pro-regular-svg-icons';
export { faGripDotsVertical as farGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
export { faHatWizard as farHatWizard } from '@fortawesome/pro-regular-svg-icons';
export { faHandPointUp as farHandPointUp } from '@fortawesome/pro-regular-svg-icons';
export { faHandshake as farHandshake } from '@fortawesome/pro-regular-svg-icons';
export { faHandWave as farHandWave } from '@fortawesome/pro-regular-svg-icons';
export { faHandsHoldingCircle as farHandsHoldingCircle } from '@fortawesome/pro-regular-svg-icons';
export { faHashtag as farHashtag } from '@fortawesome/pro-regular-svg-icons';
export { faHeadset as farHeadset } from '@fortawesome/pro-regular-svg-icons';
export { faHeart as farHeart } from '@fortawesome/pro-regular-svg-icons';
export { faHome as farHome } from '@fortawesome/pro-regular-svg-icons';
export { faHundredPoints as farHundredPoints } from '@fortawesome/pro-regular-svg-icons';
export { faIdBadge as farIdBadge } from '@fortawesome/pro-regular-svg-icons';
export { faItalic as farItalic } from '@fortawesome/pro-regular-svg-icons';
export { faImage as farImage } from '@fortawesome/pro-regular-svg-icons';
export { faImages as farImages } from '@fortawesome/pro-regular-svg-icons';
export { faIndent as farIndent } from '@fortawesome/pro-regular-svg-icons';
export { faInfo as farInfo } from '@fortawesome/pro-regular-svg-icons';
export { faKey as farKey } from '@fortawesome/pro-regular-svg-icons';
export { faLanguage as farLanguage } from '@fortawesome/pro-regular-svg-icons';
export { faLayerGroup as farLayerGroup } from '@fortawesome/pro-regular-svg-icons';
export { faLifeRing as farLifeRing } from '@fortawesome/pro-regular-svg-icons';
export { faLightbulb as farLightbulb } from '@fortawesome/pro-regular-svg-icons';
export { faLink as farLink } from '@fortawesome/pro-regular-svg-icons';
export { faList as farList } from '@fortawesome/pro-regular-svg-icons';
export { faListCheck as farListCheck } from '@fortawesome/pro-regular-svg-icons';
export { faListOl as farListOl } from '@fortawesome/pro-regular-svg-icons';
export { faListTimeline as farListTimeline } from '@fortawesome/pro-regular-svg-icons';
export { faLocationArrow as farLocationArrow } from '@fortawesome/pro-regular-svg-icons';
export { faLocationPin as farLocationPin } from '@fortawesome/pro-regular-svg-icons';
export { faLock as farLock } from '@fortawesome/pro-regular-svg-icons';
export { faMagnifyingGlass as farMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
export { faMap as farMap } from '@fortawesome/pro-regular-svg-icons';
export { faMapSigns as farMapSigns } from '@fortawesome/pro-regular-svg-icons';
export { faMegaphone as farMegaphone } from '@fortawesome/pro-regular-svg-icons';
export { faMedal as farMedal } from '@fortawesome/pro-regular-svg-icons';
export { faMessage as farMessage } from '@fortawesome/pro-regular-svg-icons';
export { faMicrophone as farMicrophone } from '@fortawesome/pro-regular-svg-icons';
export { faMicrophoneSlash as farMicrophoneSlash } from '@fortawesome/pro-regular-svg-icons';
export { faMinus as farMinus } from '@fortawesome/pro-regular-svg-icons';
export { faMobile as farMobile } from '@fortawesome/pro-regular-svg-icons';
export { faMoneyBill as farMoneyBill } from '@fortawesome/pro-regular-svg-icons';
export { faMoneyBillWave as farMoneyBillWave } from '@fortawesome/pro-regular-svg-icons';
export { faMoneyBillTransfer as farMoneyBillTransfer } from '@fortawesome/pro-regular-svg-icons';
export { faMoneyCheck as farMoneyCheck } from '@fortawesome/pro-regular-svg-icons';
export { faMoon as farMoon } from '@fortawesome/pro-regular-svg-icons';
export { faNewspaper as farNewspaper } from '@fortawesome/pro-regular-svg-icons';
export { faObjectGroup as farObjectGroup } from '@fortawesome/pro-regular-svg-icons';
export { faOutdent as farOutdent } from '@fortawesome/pro-regular-svg-icons';
export { faPaperPlane as farPaperPlane } from '@fortawesome/pro-regular-svg-icons';
export { faPause as farPause } from '@fortawesome/pro-regular-svg-icons';
export { faPencil as farPencil } from '@fortawesome/pro-regular-svg-icons';
export { faPenFancy as farPenFancy } from '@fortawesome/pro-regular-svg-icons';
export { faPercent as farPercent } from '@fortawesome/pro-regular-svg-icons';
export { faPeopleGroup as farPeopleGroup } from '@fortawesome/pro-regular-svg-icons';
export { faPhone as farPhone } from '@fortawesome/pro-regular-svg-icons';
export { faPhoneSlash as farPhoneSlash } from '@fortawesome/pro-regular-svg-icons';
export { faPiggyBank as farPiggyBank } from '@fortawesome/pro-regular-svg-icons';
export { faPlay as farPlay } from '@fortawesome/pro-regular-svg-icons';
export { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons';
export { faPowerOff as farPowerOff } from '@fortawesome/pro-regular-svg-icons';
export { faQuestion as farQuestion } from '@fortawesome/pro-regular-svg-icons';
export { faQuoteRight as farQuoteRight } from '@fortawesome/pro-regular-svg-icons';
export { faRectangleCode as farRectangleCode } from '@fortawesome/pro-regular-svg-icons';
export { faRectangleHistory as farRectangleHistory } from '@fortawesome/pro-regular-svg-icons';
export { faRightFromBracket as farRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
export { faRightToBracket as farRightToBracket } from '@fortawesome/pro-regular-svg-icons';
export { faRightLeft as farRightLeft } from '@fortawesome/pro-regular-svg-icons';
export { faRotate as farRotate } from '@fortawesome/pro-regular-svg-icons';
export { faRotateLeft as farRotateLeft } from '@fortawesome/pro-regular-svg-icons';
export { faRotateRight as farRotateRight } from '@fortawesome/pro-regular-svg-icons';
export { faRss as farRss } from '@fortawesome/pro-regular-svg-icons';
export { faClockRotateLeft as farClockRotateLeft } from '@fortawesome/pro-regular-svg-icons';
export { faRocket as farRocket } from '@fortawesome/pro-regular-svg-icons';
export { faSackDollar as farSackDollar } from '@fortawesome/pro-regular-svg-icons';
export { faScaleBalanced as farScaleBalanced } from '@fortawesome/pro-regular-svg-icons';
export { faServer as farServer } from '@fortawesome/pro-regular-svg-icons';
export { faShare as farShare } from '@fortawesome/pro-regular-svg-icons';
export { faShield as farShield } from '@fortawesome/pro-regular-svg-icons';
export { faShop as farShop } from '@fortawesome/pro-regular-svg-icons';
export { faSignIn as farSignIn } from '@fortawesome/pro-regular-svg-icons';
export { faSlashForward as farSlashForward } from '@fortawesome/pro-regular-svg-icons';
export { faSliders as farSliders } from '@fortawesome/pro-regular-svg-icons';
export { faSort as farSort } from '@fortawesome/pro-regular-svg-icons';
export { faSortDown as farSortDown } from '@fortawesome/pro-regular-svg-icons';
export { faSortUp as farSortUp } from '@fortawesome/pro-regular-svg-icons';
export { faSparkle as farSparkle } from '@fortawesome/pro-regular-svg-icons';
export { faSparkles as farSparkles } from '@fortawesome/pro-regular-svg-icons';
export { faStar as farStar } from '@fortawesome/pro-regular-svg-icons';
export { faStop as farStop } from '@fortawesome/pro-regular-svg-icons';
export { faStore as farStore } from '@fortawesome/pro-regular-svg-icons';
export { faStrikethrough as farStrikethrough } from '@fortawesome/pro-regular-svg-icons';
export { faSun as farSun } from '@fortawesome/pro-regular-svg-icons';
export { faSquare as farSquare } from '@fortawesome/pro-regular-svg-icons';
export { faSquareCode as farSquareCode } from '@fortawesome/pro-regular-svg-icons';
export { faSync as farSync } from '@fortawesome/pro-regular-svg-icons';
export { faTag as farTag } from '@fortawesome/pro-regular-svg-icons';
export { faTeddyBear as farTeddyBear } from '@fortawesome/pro-regular-svg-icons';
export { faTerminal as farTerminal } from '@fortawesome/pro-regular-svg-icons';
export { faThoughtBubble as farThoughtBubble } from '@fortawesome/pro-regular-svg-icons';
export { faThumbsUp as farThumbsUp } from '@fortawesome/pro-regular-svg-icons';
export { faTimelineArrow as farTimelineArrow } from '@fortawesome/pro-regular-svg-icons';
export { faTimer as farTimer } from '@fortawesome/pro-regular-svg-icons';
export { faToggleOff as farToggleOff } from '@fortawesome/pro-regular-svg-icons';
export { faToggleOn as farToggleOn } from '@fortawesome/pro-regular-svg-icons';
export { faTrafficLight as farTrafficLight } from '@fortawesome/pro-regular-svg-icons';
export { faTrash as farTrash } from '@fortawesome/pro-regular-svg-icons';
export { faTriangleExclamation as farTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
export { faTypewriter as farTypewriter } from '@fortawesome/pro-regular-svg-icons';
export { faUnderline as farUnderline } from '@fortawesome/pro-regular-svg-icons';
export { faUniversity as farUniversity } from '@fortawesome/pro-regular-svg-icons';
export { faUser as farUser } from '@fortawesome/pro-regular-svg-icons';
export { faUserAstronaut as farUserAstronaut } from '@fortawesome/pro-regular-svg-icons';
export { faUserCircle as farUserCircle } from '@fortawesome/pro-regular-svg-icons';
export { faUserCrown as farUserCrown } from '@fortawesome/pro-regular-svg-icons';
export { faUserGroup as farUserGroup } from '@fortawesome/pro-regular-svg-icons';
export { faUserPlus as farUserPlus } from '@fortawesome/pro-regular-svg-icons';
export { faUsers as farUsers } from '@fortawesome/pro-regular-svg-icons';
export { faVideo as farVideo } from '@fortawesome/pro-regular-svg-icons';
export { faVideoSlash as farVideoSlash } from '@fortawesome/pro-regular-svg-icons';
export { faWandMagicSparkles as farWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons';
export { faWavePulse as farWavePulse } from '@fortawesome/pro-regular-svg-icons';
export { faWebhook as farWebhook } from '@fortawesome/pro-regular-svg-icons';
export { faWrench as farWrench } from '@fortawesome/pro-regular-svg-icons';
export { faXmark as farXmark } from '@fortawesome/pro-regular-svg-icons';
