import React, { useEffect, useState } from 'react';

import { PageLoader } from '@playbooks/interface/loaders';
import { useQuery } from 'hooks';
import { timeout } from 'utils';
import * as logger from 'utils/logger';

type SubmitBountyContext = {
	bounty: any;
	setBounty: any;
	rootApi: string;
	view: string;
	onView: any;
};

export const SubmitBountyContext = React.createContext<SubmitBountyContext>(null);

const SubmitBountyProvider = ({ account, router, session, store, toast, children }) => {
	const [bounty, setBounty] = useState<any>({});
	const { bountyId, view = 'welcome' } = router.query;

	// Computed
	const rootApi = `${account.rootApi}/bounties/bounties/${bountyId}`;

	// Hooks
	useEffect(() => {
		if (session.loaded) session.isAuthenticated() ? fetchData() : '/login';
		return () => onClear();
	}, [session.loaded]);

	useEffect(() => {
		logger.debug('bountyContext: ', bounty);
	}, [bounty]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		await timeout(1000);
		const response = await store.queryRecord({
			url: rootApi,
			params: {
				include: ['framework', 'language', 'license', 'medias', 'platform', 'requirements', 'setup', 'tools', 'topics'],
			},
		});
		setBounty(response.data);
	});

	useEffect(() => {
		if (error) router.push('/403');
	}, [error]);

	const onView = view => {
		router.push(`/submit/bounty/${bounty.uuid}?view=${view}`);
		window.scrollTo(0, 0);
	};

	const onClear = () => {
		setBounty({});
	};

	// Render
	return (
		<SubmitBountyContext.Provider value={{ bounty, setBounty, rootApi, view, onView }}>
			{bounty.id ? children : <PageLoader title='Hang tight' message='Loading your workflow...' />}
		</SubmitBountyContext.Provider>
	);
};

const useSubmitBounty = () => {
	return React.useContext(SubmitBountyContext);
};

export { SubmitBountyProvider, useSubmitBounty };
