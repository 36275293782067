import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import { apiRequest, downloadRequest, storeRequest } from 'api';
import { jsonApiNormalize, jsonApiNormalizeArray, jsonApiSerialize, jsonApiSerializeArray } from 'api/serializers';
import { useStorage } from 'contexts/storage-context';
import { isArray } from 'utils';

type iStore = {
	computedHeaders: any;
	query?: any;
	queryRecord?: any;
	createRecord?: any;
	updateRecord?: any;
	saveRecord?: any;
	saveRecords?: any;
	deleteRecord?: any;
	request?: any;
	download?: any;
};

type iStoreQuery = {
	method: string;
	url: string;
	headers?: any;
	params?: any;
	data?: any;
};

const StoreContext = React.createContext<iStore>({});

const StoreProvider = ({ children }) => {
	const router = useRouter();
	const { storage } = useStorage();

	// Computed
	const computedHeaders = useMemo(() => {
		if (!storage.token?.id) return {};
		const token = storage.token?.token;
		const tempToken = storage.tempToken.token;
		const account = storage.account.uuid;
		return {
			Authorization: tempToken ? tempToken : token,
			Account: account,
		};
	}, [JSON.stringify(storage)]);

	const computeParams = params => {
		const context = params?.context || [];
		if (router.pathname.includes('/admin')) context.push(`admin`);
		return context.length > 0 ? { ...params, context } : { ...params };
	};

	// Methods
	const query = async ({ method = 'GET', url, headers, params }: iStoreQuery) => {
		const formattedHeaders = { ...computedHeaders, ...headers };
		const computedParams = computeParams(params);
		const response = await storeRequest({ method, url, headers: formattedHeaders, params: computedParams });
		return jsonApiNormalizeArray(response.data, response.included, response.meta);
	};

	const queryRecord = async ({ method = 'GET', url, headers, params }: iStoreQuery) => {
		const formattedHeaders = { ...computedHeaders, ...headers };
		const computedParams = computeParams(params);
		const response = await storeRequest({ method, url, headers: formattedHeaders, params: computedParams });
		return jsonApiNormalize(response.data, response.included);
	};

	const saveRecord = async ({ url, headers, params, data }: iStoreQuery) => {
		return data.id
			? await updateRecord({ method: 'PUT', url, headers, params, data })
			: await createRecord({ method: 'POST', url, headers, params, data });
	};

	const saveRecords = async ({ method = 'PUT', url, headers, params, data }: iStoreQuery) => {
		const formattedHeaders = { ...computedHeaders, ...headers };
		const computedParams = computeParams(params);
		const formattedData = jsonApiSerializeArray(data);
		const response = await storeRequest({
			method,
			url,
			headers: formattedHeaders,
			params: computedParams,
			data: formattedData,
		});
		return jsonApiNormalizeArray(response.data, response.included);
	};

	const createRecord = async ({ method = 'POST', url, headers, params, data }: iStoreQuery) => {
		const formattedHeaders = { ...computedHeaders, ...headers };
		const computedParams = computeParams(params);
		const formattedData = isArray(data) ? jsonApiSerializeArray(data) : jsonApiSerialize(data);
		const response = await storeRequest({
			method,
			url,
			headers: formattedHeaders,
			params: computedParams,
			data: formattedData,
		});
		return jsonApiNormalize(response.data, response.included);
	};

	const updateRecord = async ({ method = 'PUT', url, headers, params, data }: iStoreQuery) => {
		const formattedHeaders = { ...computedHeaders, ...headers };
		const computedParams = computeParams(params);
		const formattedData = isArray(data) ? jsonApiSerializeArray(data) : jsonApiSerialize(data);
		const response = await storeRequest({
			method,
			url,
			headers: formattedHeaders,
			params: computedParams,
			data: formattedData,
		});
		return jsonApiNormalize(response.data, response.included);
	};

	const deleteRecord = async ({ method = 'DELETE', url, headers, params, data }: iStoreQuery) => {
		const formattedHeaders = { ...computedHeaders, ...headers };
		const computedParams = computeParams(params);
		const formattedData = isArray(data) ? jsonApiSerializeArray(data) : jsonApiSerialize(data);
		const response = await storeRequest({
			method,
			url,
			headers: formattedHeaders,
			params: computedParams,
			data: formattedData,
		});
		return jsonApiNormalize(response.data, response.included);
	};

	const request = async ({ method = 'GET', url, headers, params, data }: iStoreQuery) => {
		const formattedHeaders = { ...computedHeaders, ...headers };
		const computedParams = computeParams(params);
		return await apiRequest({ method, url, headers: formattedHeaders, params: computedParams, data });
	};

	const download = async ({ method = 'GET', url, headers, params }: iStoreQuery) => {
		const formattedHeaders = { ...computedHeaders, ...headers };
		const computedParams = computeParams(params);
		return await downloadRequest({ method, url, headers: formattedHeaders, params: computedParams });
	};

	// Render
	return (
		<StoreContext.Provider
			value={{
				computedHeaders,
				query,
				queryRecord,
				saveRecord,
				saveRecords,
				createRecord,
				updateRecord,
				deleteRecord,
				request,
				download,
			}}>
			{children}
		</StoreContext.Provider>
	);
};

const useStore = () => {
	return React.useContext(StoreContext);
};

export { StoreProvider, useStore };
