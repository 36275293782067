import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { PageLoader } from '@playbooks/interface/loaders';
import { useQuery } from 'hooks';
import { timeout } from 'utils';
import * as logger from 'utils/logger';

type iAdmin = {
	admin: any;
	setAdmin: any;
	accordions: string[];
	setAccordions: any;
	rootApi: string;
	rootLink: string;
	onRefresh?: any;
};

const AdminContext = React.createContext<iAdmin>(null);

const AdminProvider = ({ session, store, toast, children }) => {
	const [admin, setAdmin] = useState<any>({});
	const [accordions, setAccordions] = useState(['marketplace', 'accounts', 'pipelines']);
	const router = useRouter();

	// Computed
	const rootApi = ``;
	const rootLink = `/admin`;

	// Hooks
	useEffect(() => {
		if (session.loaded) fetchData();
		return () => onClear();
	}, [session.loaded]);

	useEffect(() => {
		if (admin.id) logger.debug('adminContext: ', admin);
	}, [admin]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		await timeout(1000);
		if (session.user.role !== 'admin') throw new Error(`Sorry, you're not authorized`);
		setAdmin(session.user);
	});

	useEffect(() => {
		if (error) router.push('/403');
	}, [error]);

	// Methods
	const onRefresh = () => fetchData();

	const onClear = () => setAdmin({});

	// Render
	return (
		<AdminContext.Provider value={{ admin, setAdmin, accordions, setAccordions, rootApi, rootLink, onRefresh }}>
			{admin.id ? children : <PageLoader title='Hang tight' message='Loading dashboard...' />}
		</AdminContext.Provider>
	);
};

const useAdmin = () => {
	return React.useContext(AdminContext);
};

export { AdminProvider, useAdmin };
