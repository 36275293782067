import React, { useEffect, useState } from 'react';

import { useQuery } from 'hooks';
import * as logger from 'utils/logger';

type iCollectionContext = {
	collection?: any;
	setCollection?: any;
	rootApi: string;
	secureApi: string;
	rootLink: string;
	isOwner: boolean;
	loading: boolean;
	onUpdate: any;
	onRefresh: any;
};

const CollectionContext = React.createContext<iCollectionContext>(null);

const CollectionProvider = ({ ssr, account, session, store, toast, children }) => {
	const [collection, setCollection] = useState(ssr.collection?.data || {});
	const [loaded, setLoaded] = useState(false);

	// Computed
	const rootApi = `/collections/${collection.uuid}`;
	const rootLink = `/${collection.owner?.uuid}`;
	const secureApi = `${account.rootApi}/collections/${collection.uuid}`;
	const isOwner = account.account?.id === collection.ownerId && account.type === collection.ownerType;

	// Hooks
	useEffect(() => {
		setLoaded(true);
		return () => onClear();
	}, []);

	useEffect(() => {
		if (loaded) setCollection(ssr.collection?.data || {});
	}, [ssr?.collection]);

	useEffect(() => {
		if (collection.id) logger.debug('collectionContext: ', collection);
	}, [collection]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		const response = await store.queryRecord({ url: `/collections/${collection.uuid}` });
		setCollection(response.data);
	});

	// Methods
	const onUpdate = data => {
		setCollection({ ...collection, ...data });
	};

	const onRefresh = () => fetchData();

	const onClear = () => {
		setCollection({});
	};

	// Render
	return (
		<CollectionContext.Provider
			value={{ collection, setCollection, isOwner, rootApi, rootLink, secureApi, loading, onUpdate, onRefresh }}>
			{children}
		</CollectionContext.Provider>
	);
};

const useCollection = () => {
	return React.useContext(CollectionContext);
};

export { CollectionProvider, useCollection };
